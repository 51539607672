import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Button } from "@chakra-ui/react";
import Container from "./ui/Container";

function Tech() {
  return (
    <Container fluid>
      <h1 className="text-center font-bold text-3xl ">
        Technologies We Use For
      </h1>
      <h2 className="text-center font-bold text-xl mb-4">
        <span className="text-red-500 font-bold">
          Website and APP Design-Development
        </span>
      </h2>
      <p className="text-center text-lg ">
        As a website designing company in gurgaon we are well versed with
        technologies for Web Development. Our Website Designing team has
        experienced and skilled individuals capable of bringing your vision on
        screen. We would be happy to be your website designing company.
      </p>

      {/* technologies grid */}

      <div className="grid  gap-8  my-4  grid-cols-2 md:grid-cols-4   ">
        <div className=" m-auto py-4 px-12       ">
          <StaticImage src="../images/React.png" />
          <div>
            <h2 className="font-bold text-center">React JS</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/Node.png" />
          <div>
            <h2 className="font-bold text-center">Node JS</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/nextjs-bitdecoders 1.png" />
          <div>
            <h2 className="font-bold text-center">Next JS</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/Gatsby.png" />
          <div>
            <h2 className="font-bold text-center">Gatsby</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/HTML.png" />
          <div>
            <h2 className="font-bold text-center">HTML 5</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/Swift.png" />
          <div>
            <h2 className="font-bold text-center">Swift</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/Kotlin.png" />
          <div>
            <h2 className="font-bold text-center">Kotlin</h2>
          </div>
        </div>
        <div className=" m-auto py-4 px-12    ">
          <StaticImage src="../images/Flutter.png" />
          <div>
            <h2 className="font-bold text-center">Flutter</h2>
          </div>
        </div>
      </div>

      {/* lets talk about what we can build together section  */}
      <div className="my-16 ">
        <h2 className="text-center font-bold text-2xl ">
          Let&apos;s Talk About What We Can Build Together
        </h2>
        <p className="text-center ">
          Whatever may be your requirement - be it a simple website design, a
          complex data driven web application development, an ecommerce website,
          a native or cross platform mobile app development - we have a solution
          for you.
        </p>
        <div className="text-center mt-4">
          <Button as={Link} href="/contact-us/" colorScheme="red">
            Contact Us Now For A Free Quote!
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default Tech;
